<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true"
                    >Modification du nombre de jours lors de la relance de clôture des OS dont la date de fin
                    prévisionnelle des OS est dépassé</vk-breadcrumb-item
                >
            </vk-breadcrumb>
        </div>

        <div class="uk-padding-small">
            <div class="mb-3">
                <label for="daysNumber" class="form-label">Nombre de jours</label>
                <input type="number" class="form-control" id="daysNumber" :min="1" :step="1" v-model="input" />
                <p class="text-danger" v-if="error">{{ error }}</p>
            </div>
            <div class="mb-3">
                <div class="btn-group" role="group" v-if="hasDataChanded">
                    <button class="btn btn-primary" @click="cancelInput">Annuler</button>
                    &nbsp;
                    <button class="btn btn-warning" @click="submit">
                        Enregistrer
                        <div v-if="saveInProgress" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import { mapActions } from 'vuex'

export default {
    name: 'RelanceOS',
    data: () => ({
        input: 1,
        current: 1,
        error: null,
        saveInProgress: false,
    }),
    computed: {
        hasDataChanded() {
            return this.current != this.input
        },
    },
    methods: {
        ...mapActions('notifications', ['addNotification']),
        submit() {
            const error = this.invalidInput(this.input)
            if (!error) {
                this.saveInProgress = true
                backendApi
                    .saveRelaunchOsDays({
                        numberOfDaysExceeded: parseInt(this.input),
                    })
                    .then(() => {
                        this.current = this.input
                        this.resetState()
                        this.addNotification({
                            status: 'success',
                            message: 'Mise à jour du nombre de jours du relance OS avec succès',
                        })
                    })
            } else {
                this.error = error
            }
        },
        resetState() {
            this.saveInProgress = false
            this.error = null
        },
        cancelInput() {
            this.input = this.current
            if (this.error) this.error = null
        },
        loadData() {
            backendApi.getRelaunchOsDays().then((response) => {
                this.current = response.numberOfDaysExceeded
                this.input = response.numberOfDaysExceeded
            })
        },
        invalidInput(input) {
            if (input === undefined || input === null || input === '') return 'Le champs nombre de jours est réquis'
            if (isNaN(Number(input)) || !Number.isInteger(Number(input)))
                return 'Le champs nombre de jours doit être un nombre entier'
            if (Number(input) <= 0) return 'Le nombre de jours doit être supérieur à 0'
            return null
        },
    },
    created() {
        this.loadData()
    },
}
</script>
